<!--
 * @Author: FengHang
 * @LastEditors: FengHang
 * @Date: 2021-06-04 18:10:10
 * @LastEditTime: 2021-06-10 17:09:19
-->
<template>
	<div class="">
		<van-pull-refresh class="inform" v-model="fresh" @refresh="onRefresh">
			<van-list offset="10" v-model="loadMore" :finished="loadFinish"
				:finished-text="messageList.length >= 6?'没有更多了':''" @load="scrollToLower">
				<div class="inform-list" v-if="messageList.length > 0&&!showEmpty">
					<div v-for="(item, index) in messageList" :key="index"
						class="inform-list-item"
						@click="handleJobDetail(item)"
						>
						<div class="list-item-time">
							{{item.createTime}}
						</div>
						<div class="list-item-content">
							<div class="item-content-title">
								<span>{{item.title}}</span>
							</div>
							<div class="item-content-val">
								{{item.content}}
							</div>
						</div>
					</div>
				</div>
				<div v-if="messageList.length == 0&&showEmpty" class="inform-empty">
					<img src="@/assets/images/mobile/common/empty2.png" alt="">
					<span>暂无消息</span>
				</div>
			</van-list>
		</van-pull-refresh>
	</div>
</template>
<script>
	import {
		messageListMb,
		messageUnreadClearMb,
	} from "@/api/mobile/mine"
	import storage from "store";
	export default {
		data() {
			return {
				messageList: [
					// 	{
					// 	title: "岗位面试通知",
					// 	content: "您好同学，你报名参加的岗位对你发出了面试邀请，希望 你在明天12点去教务处进行面试",
					// 	time: "2022-06-21 10:21"
					// }, {
					// 	title: "岗位录用通知",
					// 	content: "您好同学，恭喜您报名参加的xx岗位已被录用",
					// 	time: "2022-06-21 10:21"
					// },
				],
				pageIndex: 1,
				pageSize: 6,
				totalCount: 0,
				fresh: false,
				loadMore: false,
				loadFinish: false,
				showEmpty: false,
				userInfo:{},
			}
		},
		mounted() {
			storage.remove("signUpData")
			this.getMessageList()
			this.messageUnreadClear(0)
			this.userInfo = storage.get("accessInfo")
		},
		methods: {
			onRefresh() {
				this.pageIndex = 1
				setTimeout(() => {
					this.messageList = []
					this.getMessageList()
				}, 1000);
			},
			scrollToLower() {
				// 加载状态结束
				if (this.totalCount > this.pageSize && this.pageIndex > 1) {
					this.getMessageList()
				}
			},
			// 获取消息列表
			getMessageList() {
				return new Promise((resolve, reject) => {
					this.loadFinish = false
					this.showEmpty = false
					let params = {
						pageIndex: this.pageIndex,
						pageSize: this.pageSize
					}
					messageListMb(params).then((res) => {
						// console.log("getMessageList", res)
						if (res.code == 0) {
							if (this.pageIndex == 1) {
								this.fresh = false;
							}
							this.loadMore = false
							res.data.forEach(item => {
								this.$set(item, "status", 1)
							})
							this.messageList = this.messageList.concat(res.data)
							this.totalCount = res.page.totalCount
							if (this.messageList.length >= res.page.totalCount * 1) {
								this.loadFinish = true
							}
							if (this.messageList.length == 0) {
								this.showEmpty = true
							}
							this.pageIndex++
							resolve()
						} else {
							this.loadMore = false
							this.loadFinish = true
							this.fresh = false
							this.showEmpty = false
							reject()
						}
					}).catch((err) => {
						this.loadMore = false
						this.loadFinish = true
						this.fresh = false
						this.showEmpty = false
						reject()
					})
				})
			},
			// 清除消息未读
			messageUnreadClear(id) {
				let params = {
					id: id
				}
				messageUnreadClearMb(id, params).then((res) => {})
			},
			//点击跳转岗位详情
			handleJobDetail(item){
				if(item.bizType==='WORKING_HOURS_REJECT'){
					this.$router.push({
						path: `/mb/manhour/detail?id=${item.jumpId}`
					})
				}else{
					this.$router.push({
						path: `/mb/activity/detail?id=${item.jumpId}`
					})
				}
			},
		},
	}
</script>
<style lang="scss" scoped>
	.inform {
		
		.inform-list {
			display: flex;
			flex-direction: column;

			.inform-list-item {
				display: flex;
				flex-direction: column;

				.list-item-time {
					display: flex;
					align-items: center;
					justify-content: center;
					font-size: 0.24rem;
					font-family: PingFang SC;
					font-weight: 400;
					height: 0.78rem;
					color: #B4BCC6;
				}

				.list-item-content {
					background: #FFFFFF;
					padding: 0.28rem 0.28rem;
					box-sizing: border-box;
					display: flex;
					flex-direction: column;

					.item-content-title {
						display: flex;
						align-items: center;
						justify-content: space-between;
						padding-bottom: 0.16rem;
						border-bottom: 0.02rem solid #DCDFE6;

						span {
							font-size: 0.28rem;
							font-family: PingFang SC;
							font-weight: bold;
							height: 0.4rem;
							color: #303133;
						}
					}

					.item-content-val {
						min-height: 0.4rem;
						font-size: 0.28rem;
						font-family: PingFang SC;
						font-weight: 400;
						line-height: 0.4rem;
						color: #606266;
						margin-top: 0.16rem;
						word-break: break-all;
					}

				}
			}
		}

		.inform-empty {
			height: 100%;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			padding: 2rem 0;

			img {
				width: 2.8rem;
				height: 2.8rem;
				margin-bottom: 0.4rem;
			}

			span {
				font-size: 0.28rem;
				font-family: PingFang SC-Regular, PingFang SC;
				font-weight: 400;
				color: #909399;
				line-height: 0.4rem;
			}
		}
	}
</style>
